<template>
  <div class="row mt-5">
    <div class="col-4 offset-4">
      <div class="card">
        <div class="card-header">
          Error: {{ code }}
        </div>
        <div class="card-body">
          <h5 class="card-title">
            {{ title }}
          </h5>
        </div>
        <div class="card-footer text-end">
          <a
            class="btn btn-sm btn-primary"
            href=""
            @click="removeCredentials"
          >Go home</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'Error',
  data: () => ({
    errors: {
      UNKNOWN_ERROR: {
        code: 400,
        title: 'Unknown error'
      },
      PAGE_NOT_FOUND: {
        code: 404,
        title: 'Page not found'
      },
      FORBIDDEN: {
        code: 403,
        title: 'Check your permissions or contact administrator of the portal.'
      }
    },
    code: null,
    title: null
  }),
  mounted() {
    const errors = this.errors;
    let result = errors.UNKNOWN_ERROR;
    const errorCode = Number(this.$route.params.code);
    for (const key in errors) {
      const value = errors[key];
      if (value.code === errorCode) {
        result = value;
        break;
      }
    }
    this.code = result.code;
    this.title = result.title;
  },
  methods: {
    ...mapActions({
      authLogOut: 'auth/logOut'
    }),
    removeCredentials() {
      this.authLogOut();
      this.$router.push('/auth');
    }
  }
};
</script>
